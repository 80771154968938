@import "variables";

#search_block_top {
	padding-top:10px;
	padding-bottom:10px;
}
@media(max-width:991px){
	#search_block_top {
		padding-top:0;
		padding-bottom:0;
	}
}
  #search_block_top #searchbox {
    float: left;
    width: 100%; }
  #search_block_top .btn.button-search {
	background:transparent;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	color: #777778;
	width: 50px;
	text-align: center;
	font-size:21px;
}

#search_block_top .btn.button-search span {
  display: none; }
#search_block_top .btn.button-search:before {
  content: "\f002";
  display: block;
  font-family: "FontAwesome";
  font-size: 21px;
  width: 100%;
  text-align: center; }
#search_block_top .btn.button-search:hover {
  color: #6f6f6f; }

@media(max-width:991px){
	#search_block_top .btn.button-search {
		width:auto;
		padding:0;
		padding-top:5px;
		background:theme-color(white);
	}
	#search_block_top .btn.button-search:before,
	.toggle-search i{
		cursor:pointer;
		font-size:25px;
	}
	.toggle-search i{
		padding-top:10px;
		color:#777;
	}
	#search_block_top form {
		display:none;
	}
	#search_block_top form.open{
		display:block;
		position:fixed;
		width:90vw !important;
		top:50%;
		left:5vw;
		z-index:500;
	}
	#search_block_top form.open input[type=text]{
		padding:15px 30px 15px 15px !important;
		border-radius:none !important;
	}
	#search_block_top form.open button{
		margin-right:15px;
	}
	.overlay-search{
		position:fixed;
		width:100vw;
		height:100%;
		background:rgba(0,0,0,0.6);
		top:0;
		left:0;
		z-index:300;
	}
	.ac_results{
		display:none !important;
		width: 90vw !important;
	}
}


#search_block_top #search_query_top {
	display: inline;
	padding: 13px 60px 13px 0;
	height: 45px;
	background:theme-color(white);
	border:none;
	border-bottom:#e8e8e8 solid 1px;
	width:100%;
}
.ac_results {
  background: theme-color(white);
  border: 1px solid #d6d4d4;
  width: 271px;
  margin-top: -1px; }
  .ac_results li {
    padding: 0 10px;
    font-weight: normal;
    color: #686666;
    font-size: 13px;
    line-height: 22px; }
    .ac_results li.ac_odd {
      background: theme-color(white); }
    .ac_results li:hover, .ac_results li.ac_over {
      background: #fbfbfb; }

form#searchbox {
  position: relative; }
  form#searchbox label {
    color: #333; }
  form#searchbox input#search_query_block {
    margin-right: 10px;
    max-width: 222px;
    margin-bottom: 10px;
    display: inline-block;
    float: left; }
  form#searchbox .button.button-small {
    float: left; }
    form#searchbox .button.button-small i {
			margin-right: 0; }
			
@media(max-width:340px){
	#search_block_top{
		padding-right:10px;
	}
}